define("ember-cli-pagination/validate", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    var Validate = Ember.Object.extend();

    Validate.reopenClass({
      internalErrors: [],

      internalError: function(str,obj) {
        this.internalErrors.push(str);
        Ember.Logger.warn(str);
        if (obj) {
          Ember.Logger.warn(obj);
        }
      },

      getLastInternalError: function() {
        return this.internalErrors[this.internalErrors.length-1];
      }
    });

    __exports__["default"] = Validate;
  });