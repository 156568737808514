define("ember-radio-button/components/labeled-radio-button", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    var computed = Ember.computed;

    __exports__["default"] = Ember.Component.extend({
      tagName: 'label',
      classNameBindings: ['checked'],
      classNames: ['ember-radio-button'],
      defaultLayout: null, // ie8 support

      checked: computed('groupValue', 'value', function(){
        return this.get('groupValue') === this.get('value');
      }).readOnly(),

      actions: {
        innerRadioChanged: function(value) {
          this.sendAction('changed', value);
        }
      }
    });
  });