define("ember-cli-pagination/factory", 
  ["ember","ember-cli-pagination/remote/controller-mixin","ember-cli-pagination/local/controller-local-mixin","ember-cli-pagination/remote/route-mixin","ember-cli-pagination/local/route-local-mixin","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __dependency4__, __dependency5__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var PageControllerMixin = __dependency2__["default"];
    var PageControllerLocalMixin = __dependency3__["default"];
    var PageRouteMixin = __dependency4__["default"];
    var PageRouteLocalMixin = __dependency5__["default"];

    var Factory = Ember.Object.extend({
      paginationTypeInner: function() {
        var res = this.get('config').paginationType;
        if (res) {
          return res;
        }
        var ops = this.get('config').pagination;
        if (ops) {
          return ops.type;
        }
        return null;
      },

      paginationType: function() {
        var res = this.paginationTypeInner();
        if (!(res === "local" || res === "remote")) {
          throw "unknown pagination type";
        }
        return res;
      },

      controllerMixin: function() {
        return {
          local: PageControllerLocalMixin,
          remote: PageControllerMixin
        }[this.paginationType()];
      },

      routeMixin: function() {
        return {
          local: PageRouteLocalMixin,
          remote: PageRouteMixin
        }[this.paginationType()];
      }
    });

    Factory.reopenClass({
      controllerMixin: function(config) {
        return Factory.create({config: config}).controllerMixin();
      },
      routeMixin: function(config) {
        return Factory.create({config: config}).routeMixin();
      }
    });

    __exports__["default"] = Factory;
  });