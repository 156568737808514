define("ember-cli-pagination/local/route-local-mixin", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Mixin.create({
      findPaged: function(name) {
        return this.store.find(name);
      }
    });
  });