define("ember-cli-pagination/remote/controller-mixin", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];


    __exports__["default"] = Ember.Mixin.create({
      queryParams: ["page", "perPage"],
      
      pageBinding: "content.page",

      totalPagesBinding: "content.totalPages",

      pagedContentBinding: "content"
    });
  });