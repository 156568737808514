define("ember-cli-pagination/remote/paged-remote-array", 
  ["ember","ember-cli-pagination/util","ember-cli-pagination/watch/lock-to-range","ember-cli-pagination/remote/mapping","ember-cli-pagination/page-mixin","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __dependency4__, __dependency5__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var Util = __dependency2__["default"];
    var LockToRange = __dependency3__["default"];
    var QueryParamsForBackend = __dependency4__.QueryParamsForBackend;
    var ChangeMeta = __dependency4__.ChangeMeta;
    var PageMixin = __dependency5__["default"];

    var ArrayProxyPromiseMixin = Ember.Mixin.create(Ember.PromiseProxyMixin, {
      then: function(success,failure) {
        var promise = this.get('promise');
        var me = this;

        promise.then(function() {
          success(me);
        }, failure);
      }
    });

    __exports__["default"] = Ember.ArrayProxy.extend(PageMixin, Ember.Evented, ArrayProxyPromiseMixin, {
      page: 1,
      paramMapping: function() {
        return {};
      }.property(''),

      init: function() {
        var initCallback = this.get('initCallback');
        if (initCallback) {
          initCallback(this);
        }

        try {
          this.get('promise');
        }
        catch (e) {
          this.set('promise', this.fetchContent());
        }
      },

      addParamMapping: function(key,mappedKey,mappingFunc) {
        var paramMapping = this.get('paramMapping') || {};
        if (mappingFunc) {
          paramMapping[key] = [mappedKey,mappingFunc];
        }
        else {
          paramMapping[key] = mappedKey;
        }
        this.set('paramMapping',paramMapping);
        this.incrementProperty('paramsForBackendCounter');
        //this.pageChanged();
      },

      addQueryParamMapping: function(key,mappedKey,mappingFunc) {
        return this.addParamMapping(key,mappedKey,mappingFunc);
      },

      addMetaResponseMapping: function(key,mappedKey,mappingFunc) {
        return this.addParamMapping(key,mappedKey,mappingFunc);
      },

      paramsForBackend: function() {
        var paramsObj = QueryParamsForBackend.create({page: this.getPage(), 
                                                      perPage: this.getPerPage(), 
                                                      paramMapping: this.get('paramMapping')});
        var ops = paramsObj.make();

        // take the otherParams hash and add the values at the same level as page/perPage
        ops = Util.mergeHashes(ops,this.get('otherParams')||{});

        return ops;
      }.property('page','perPage','paramMapping','paramsForBackendCounter'),

      rawFindFromStore: function() {
        var store = this.get('store');
        var modelName = this.get('modelName');

        var ops = this.get('paramsForBackend');
        var res = store.find(modelName, ops);

        return res;
      },

      fetchContent: function() {
        var res = this.rawFindFromStore();
        this.incrementProperty("numRemoteCalls");
        var me = this;

        res.then(function(rows) {
          var metaObj = ChangeMeta.create({paramMapping: me.get('paramMapping'),
                                           meta: rows.meta,
                                           page: me.getPage(),
                                           perPage: me.getPerPage()});

          return me.set("meta", metaObj.make());
          
        }, function(error) {
          Util.log("PagedRemoteArray#fetchContent error " + error);
        });

        return res;
      },  

      totalPagesBinding: "meta.total_pages",

      pageChanged: function() {
        this.set("promise", this.fetchContent());
      }.observes("page", "perPage"),

      lockToRange: function() {
        LockToRange.watch(this);
      },

      watchPage: function() {
        var page = this.get('page');
        var totalPages = this.get('totalPages');
        if (parseInt(totalPages) <= 0) {
          return;
        }

        this.trigger('pageChanged',page);

        if (page < 1 || page > totalPages) {
          this.trigger('invalidPage',{page: page, totalPages: totalPages, array: this});
        }
      }.observes('page','totalPages')
    });
  });