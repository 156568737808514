define("ember-cli-pagination/remote/route-mixin", 
  ["ember","ember-cli-pagination/remote/paged-remote-array","ember-cli-pagination/util","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var PagedRemoteArray = __dependency2__["default"];
    var Util = __dependency3__["default"];

    __exports__["default"] = Ember.Mixin.create({
      perPage: 10,
      startingPage: 1,

      model: function(params) {
        return this.findPaged(this._findModelName(this.get('routeName')), params);
      },

      _findModelName: function(routeName) {
          return Ember.String.singularize(
            Ember.String.camelize(routeName)
          );
      },

      findPaged: function(name, params, callback) {
        var mainOps = {
          page: params.page || this.get('startingPage'),
          perPage: params.perPage || this.get('perPage'),
          modelName: name,
          store: this.store
        };

        if (params.paramMapping) {
          mainOps.paramMapping = params.paramMapping;
        }

        var otherOps = Util.paramsOtherThan(params,["page","perPage","paramMapping"]);
        mainOps.otherParams = otherOps;

        mainOps.initCallback = callback;

        return PagedRemoteArray.create(mainOps);
      }
    });
  });