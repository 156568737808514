define("ember-multiselect-checkboxes/components/multiselect-checkboxes", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Component.extend({
      classNames: ['multiselect-checkboxes'],

      tagName: 'ul',

      options: null,

      selection: null,

      labelProperty: null,

      disabled: false,
    });
  });