define("ember-cli-pagination/page-mixin", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Mixin.create({
      getPage: function() {
        return parseInt(this.get('page') || 1);
      },

      getPerPage: function() {
        return parseInt(this.get('perPage'));
      }
    });
  });