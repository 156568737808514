define("ember-cli-pagination/computed/paged-array", 
  ["ember","ember-cli-pagination/local/paged-array","ember-cli-pagination/infinite/paged-infinite-array","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var PagedArray = __dependency2__["default"];
    var PagedInfiniteArray = __dependency3__["default"];

    function makeLocal(contentProperty,ops) {
      return Ember.computed("",function() {
        var pagedOps = {}; //{content: this.get(contentProperty)};
        pagedOps.parent = this;

        var getVal = function(key,val) {
          if (key.match(/Binding$/)) {
            return "parent."+val;
            //return Ember.Binding.oneWay("parent."+val);
          }
          else {
            return val;
          }
        };

        for (var key in ops) {
          pagedOps[key] = getVal(key,ops[key]);
        }

        var paged = PagedArray.extend({
          contentBinding: "parent."+contentProperty
        }).create(pagedOps);
        // paged.lockToRange();
        return paged;
      });
    }

    function makeInfiniteWithPagedSource(contentProperty /*, ops */) {
      return Ember.computed(function() {
        return PagedInfiniteArray.create({all: this.get(contentProperty)});
      });
    }

    function makeInfiniteWithUnpagedSource(contentProperty,ops) {
      return Ember.computed(function() {
        ops.all = this.get(contentProperty);
        return PagedInfiniteArray.createFromUnpaged(ops);
      });
    }

    __exports__["default"] = function(contentProperty,ops) {
      ops = ops || {};

      if (ops.infinite === true) {
        return makeInfiniteWithPagedSource(contentProperty,ops);
      }
      else if (ops.infinite) {
        return makeInfiniteWithUnpagedSource(contentProperty,ops);
      }
      else {
        return makeLocal(contentProperty,ops);
      }
    }
  });