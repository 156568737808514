define("ember-cli-pagination/util/safe-get", 
  ["ember","ember-cli-pagination/validate","ember-cli-pagination/util","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var Validate = __dependency2__["default"];
    var Util = __dependency3__["default"];

    __exports__["default"] = Ember.Mixin.create({
      getInt: function(prop) {
        var raw = this.get(prop);
        if (raw === 0 || raw === "0") {
          // do nothing
        }
        else if (Util.isBlank(raw)) {
          Validate.internalError("no int for "+prop+" val is "+raw);
        }
        return parseInt(raw);
      }
    });
  });