define("ember-cli-pagination/lib/page-items", 
  ["ember","ember-cli-pagination/util","ember-cli-pagination/lib/truncate-pages","ember-cli-pagination/util/safe-get","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __dependency4__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var Util = __dependency2__["default"];
    var TruncatePages = __dependency3__["default"];
    var SafeGet = __dependency4__["default"];

    __exports__["default"] = Ember.Object.extend(SafeGet, {
      pageItemsAll: function() {
        var currentPage = this.getInt("currentPage");
        var totalPages = this.getInt("totalPages");
        Util.log("PageNumbers#pageItems, currentPage " + currentPage + ", totalPages " + totalPages);

        var res = [];
        for(var i=1; i<=totalPages; i++) {
          res.push({
            page: i,
            current: currentPage === i
          });
        }
        return res;
      }.property("currentPage", "totalPages"),

      pageItemsTruncated: function() {
        var currentPage = this.getInt('currentPage');
        var totalPages = this.getInt("totalPages");
        var toShow = this.getInt('numPagesToShow');
        var showFL = this.get('showFL');

        var t = TruncatePages.create({currentPage: currentPage, totalPages: totalPages, 
                                      numPagesToShow: toShow,
                                      showFL: showFL});
        var pages = t.get('pagesToShow');

        return pages.map(function(page) {
          return {
            page: page,
            current: (currentPage === page)
          };
        });
      }.property('currentPage','totalPages','numPagesToShow'),

      pageItems: function() {
        if (this.get('truncatePages')) {
          return this.get('pageItemsTruncated');
        }
        else {
          return this.get('pageItemsAll');
        }
      }.property('currentPage','totalPages','truncatePages','numPagesToShow')
    });
  });