define("ember-radio-button/components/radio-button-base", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    var boundAttributeKeys = [
      'checked',
      'disabled',
      'name',
      'required',
      'type',
      'value'
    ];
    __exports__.boundAttributeKeys = boundAttributeKeys;
    __exports__["default"] = Ember.Component.extend({
      tagName: 'input',
      type: 'radio',
      value: null,
      defaultLayout: null, // ie8 support

      attributeBindings: boundAttributeKeys
    });
  });